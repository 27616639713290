<template>
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <Breadcrumb :datas='breadcrumb_data' />
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container-fluid">
        <!--begin::Card-->
        <div class="card card-custom">
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label">
                {{ $t('rates_management.exchange_rates_history') }}
              </h3>
            </div>
            <div class="card-toolbar">
              
            </div>
          </div>
          <RatesHistory />
          <div class="divider"></div>
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label">
                {{ $t('rates_management.exchange_rates') }}
              </h3>
            </div>
            <div class="card-toolbar">
              
            </div>
          </div>
          <ExchangeRates />
          <div class="divider"></div>
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label">
                {{ $t('rates_management.supplier_prices') }}
              </h3>
            </div>
            <div class="card-toolbar">
              
            </div>
          </div>
          <SupplierPrices />

        </div>
        <!--end::Card-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Entry-->
  </div>
</template>

<script>
import Breadcrumb from '@/components/layout/Breadcrumb.vue';
import ExchangeRates from './ExchangeRates.vue';
import SupplierPrices from './SupplierPrices.vue';
import { mapState } from 'vuex';
import RatesHistory from './RatesHistory.vue';

export default {
  data() {
    return {
    }
  },
  components: {
    Breadcrumb,
    ExchangeRates,
    SupplierPrices,
    RatesHistory
  },
  computed: {
    breadcrumb_data() {
      return {
        title:  this.$t('general_management.title'),
        subtitle: [
          this.$t('general_management.rates_title')
        ]
      }
    },
    ...mapState({
      permissions: (state) => state.customer.permissions,
    }),
  },
  methods: {
    checkPermission(permission) {
      return this.permissions?.some(
        (item) => String(item.name) === String(permission)
      );
    },
  },
  created() {
    if (this.permissions?.length > 0 && !this.checkPermission("crm.finance.currencies.rates")) {
      this.$router.push("/no-permission");
    }
  }
}
</script>
