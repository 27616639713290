<template>
    <div class="card-body pt-0">
        <div class="row mb-md-2">
            <div class="col-12 col-md-3 mb-sm-1">
                <div class="input-group">
                    <multiselect v-model="selectedCurrency" deselect-label="" select-label="" selected-label=""
                        id="currency" track-by="name" :loading="currenciesLoading" :label="$t('commons.currency')"
                        :custom-label="currencySelectCustomLabel" :show-labels="false" :placeholder="$t('commons.currency')"
                        :options="currencies" @select="selectedBaseCurrency = null" :allow-empty="false" open-direction="bottom">
                        <template slot="option" slot-scope="{ option }">{{ `${option.name} (${option.code})`
                        }}</template>
                        <template slot="noOptions">{{ $t('commons.no_data_available') }}</template>
                        <template slot="noResult">{{ $t('commons.no_data_available') }}</template>
                    </multiselect>
                    <span class="error__message">{{ currencyError }}</span>
                </div>
            </div>
            <i class="la la-slash icon-3x d-md-block d-sm-none"></i>
            <div class="col-12 col-md-3 mb-sm-1">
                <div class="input-group">
                    <multiselect v-model="selectedBaseCurrency" deselect-label="" select-label="" selected-label=""
                        id="currency" track-by="name" :loading="currenciesLoading" :label="$t('commons.currency')"
                        :custom-label="currencySelectCustomLabel" :show-labels="false" :placeholder="$t('commons.currency')"
                        :options="filteredBaseCurrencies" :allow-empty="false" open-direction="bottom">
                        <template slot="option" slot-scope="{ option }">{{ `${option.name} (${option.code})`
                        }}</template>
                        <template slot="noOptions">{{ $t('commons.no_data_available') }}</template>
                        <template slot="noResult">{{ $t('commons.no_data_available') }}</template>
                    </multiselect>
                    <span class="error__message">{{ baseCurrencyError }}</span>
                </div>
            </div>
            <div class="col-12 col-md-4 mb-sm-1">
                <div class="input-group">
                    <input class="form-control" ref="dateRangePicker" :placeholder="$t('commons.date')" style="min-height: 43px;" />
                    <div class="input-group-append cursor-pointer hover-opacity-80" @click="resetDate">
                        <span class="input-group-text">
                            <i class="la la-times-circle-o text-dark"></i>
                            {{ $t("commons.reset") }}
                        </span>
                    </div>
                </div>
            </div>
            <button class="btn btn-primary btn-primary--icon ml-sm-4" @click="applyFilters">
                <span>
                    <i class="la la-search"></i>
                    <span>{{ $t("commons.submit") }}</span>
                </span>
            </button>
            
        </div>
        <div class="row mb-3">
        </div>
        <div v-if="ratesHistoryLoading">
            <div class="text-center">{{ $t("commons.data_loading") }}...</div>
        </div>
        <div class="table-responsive" v-else>
            <table class="table table-head-bg" style="width: 100%">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">{{ $t("rates_management.buying") }}</th>
                        <th scope="col">{{ $t("rates_management.selling") }}</th>
                        <th scope="col">{{ $t("commons.creation_date") }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="ratesHistory.length === 0">
                        <td colspan="7" class="text-center">{{ $t('commons.no_data_available') }}</td>
                    </tr>
                    <tr v-for="(item, key) in ratesHistory" :key="key">
                        <td>{{ item.id }}</td>
                        <td>{{ item.buying }}</td>
                        <td>{{ item.selling }}</td>
                        <td>{{ item.created_at | dateFormat }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import $ from "jquery";
import moment from "moment";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import 'bootstrap-daterangepicker/daterangepicker.css'; // Import Date Range Picker CSS
import 'bootstrap-daterangepicker';
import dateFormat from "@/config/_date-format";

export default {
    data() {
        return {
            selectedCurrency: null,
            from: null,
            to: null,
            currencyError: null,
            selectedBaseCurrency: null,
            baseCurrencyError: null,
        };
    },
    components: { Multiselect },
    filters: { dateFormat },
    computed: {
        ...mapState({
            currenciesLoading: (state) => state.currenciesManagement.isLoading,
            currencies: (state) => state.currenciesManagement.currencies,
            ratesHistoryLoading: (state) => state.ratesManagement.ratesHistoryLoading,
            ratesHistory: (state) => state.ratesManagement.ratesHistory,
        }),
        filteredBaseCurrencies() {
            if(this.selectedCurrency) {
                return this.currencies.map(val => ({ ...val, $isDisabled: `${val.id}` === `${this.selectedCurrency?.id}` }));
            }
            return this.currencies;
        }
    },
    methods: {
        ...mapActions("currenciesManagement", ["GET_FINANCE_CRM_CURRENCIES"]),
        ...mapActions("ratesManagement", ["GET_RATES_HISTORY"]),
        currencySelectCustomLabel({ name, code }) {
            return `${name} (${code})`;
        },
        resetDate() {
            const $datePicker = $(this.$refs.dateRangePicker);
            const defaultStartDate = moment().subtract(30, 'minutes');
            const defaultEndDate = moment();

            // Set the Date Range Picker to the default dates
            $datePicker.data('daterangepicker').setStartDate(defaultStartDate);
            $datePicker.data('daterangepicker').setEndDate(defaultEndDate);
            this.from = defaultStartDate.format('DD-MM-YYYY:HH:mm');
            this.to = defaultEndDate.format('DD-MM-YYYY:HH:mm');
        },
        applyFilters() {
            this.currencyError = null;
            if (!this.selectedCurrency) {
                this.currencyError = 'Required';
                return;
            }
            if (!this.selectedBaseCurrency) {
                this.baseCurrencyError = 'Required';
                return;
            }
            const formData = {
                from: this.from,
                to: this.to,
                currency_id: this.selectedCurrency.id,
                base_currency_id: this.selectedBaseCurrency.id
            }
            this.GET_RATES_HISTORY(formData);
        }
    },
    created() {
        this.GET_FINANCE_CRM_CURRENCIES()
    },
    mounted() {
        const $datePicker = $(this.$refs.dateRangePicker);
        const defaultOptions = {
            startDate: moment().subtract(30, 'minutes'),
            endDate: moment(),
            timePicker: true,
            timePicker24Hour: true,
            opens: 'left',
            locale: {
                format: 'YYYY-MM-DD HH:mm:ss',
            },
        };
        this.from = defaultOptions.startDate.format('DD-MM-YYYY:HH:mm');
        this.to = defaultOptions.endDate.format('DD-MM-YYYY:HH:mm');

        $datePicker.daterangepicker(defaultOptions);

        // Listen for the 'apply.daterangepicker' event
        $datePicker.on('apply.daterangepicker', (event, picker) => {
            const startDate = picker.startDate.format('YYYY-MM-DD HH:mm:ss');
            const endDate = picker.endDate.format('YYYY-MM-DD HH:mm:ss');
            this.from = picker.startDate.format('DD-MM-YYYY:HH:mm')
            this.to = picker.endDate.format('DD-MM-YYYY:HH:mm')
            console.log(`Selected date range: ${startDate} to ${endDate}`);
        });
    },
    beforeDestroy() {
        $(this.$refs.dateRangePicker).data('daterangepicker').remove();
    }
};
</script>

  